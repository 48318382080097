import React from 'react';
import { ReactComponent as PoweredByLokk } from 'assets/logos/PoweredByLokk.svg'

const Footer = () => {
  return (
    <div className="h-full flex justify-center items-center">
      <PoweredByLokk width="150" className="fill-black" />
    </div>
  )
}

export default Footer;