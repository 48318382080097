import React from 'react';
import TopBar from 'components/navigation/TopBar';
import Footer from 'components/navigation/Footer';
import Loading from 'components/modals/Loading';
import { appSettings } from 'config/app';
import { Toaster } from 'react-hot-toast';
import { WebSocket } from 'helpers/socket';

const Vertical = ({ children }) => {
  return (
    <div className="flex flex-col items-center w-screen h-[100dvh] bg-white font-extralight text-gray-700">
      <Loading />
      <Toaster
        position="bottom-right"
        toastOptions={appSettings.toast}
      />
      {appSettings.socket.active &&
        <div className="absolute top-5 start-5">
          <WebSocket showIconStatus />
        </div>
      }
      <div className="w-full h-[10%] flex items-center justify-center">
        <TopBar />
      </div>
      <div className="w-full h-[80%] [&>*]:h-full [&>*]:w-full px-8">
        {children}
      </div>
      <div className="w-full h-[10%] flex items-center justify-center">
        <Footer />
      </div>
    </div>
  )
}

export default Vertical;