import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateLanguage } from 'store/apps/app';
import { appSettings } from 'config/app';
import LanguageButton from 'components/common/LanguageButton';

const TopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { language } = useSelector((state) => state.app);

  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    dispatch(updateLanguage(lang));
    i18n.changeLanguage(lang);
  }

  return (
    <div className="grid grid-cols-12 gap-x-5 px-8">
      <div className="col-span-5 flex justify-start w-full">
        <img
          src={appSettings.logo.url}
          alt={appSettings.logo.alt}
          onClick={() => navigate('/')}
        />
      </div>
      <div className="col-span-7 flex justify-end items-center w-full gap-x-5">
        <LanguageButton outlined={language !== 'pt'} onClick={() => changeLanguage('pt')} size="md">
          PT
        </LanguageButton>
        <LanguageButton outlined={language !== 'en'} onClick={() => changeLanguage('en')} size="md">
          EN
        </LanguageButton>
      </div>
    </div>
  )
}

export default TopBar;